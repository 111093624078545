import React from 'react';
import './App.css';


// import Login from './Login';
// import PaalStats from './PaalStats';
//RoutesComponents

import {AuthProvider} from 'react-auth-kit'
import RoutesComponent from './Routes';

function App() {
    return (
      <AuthProvider
        authName={"_auth"}
        authType={"cookie"}
        cookieDomain={window.location.hostname}
        SameSite={"none"}
        cookieSecure={window.location.protocol === "https:"}
      >
        <RoutesComponent/>
      </AuthProvider>
    );
  }
  
  export default App;