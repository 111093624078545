import React, { useState } from 'react';
import {useIsAuthenticated, useSignIn} from 'react-auth-kit'
import {useNavigate, Navigate} from 'react-router-dom'
import './Login.css'; // Import the CSS file here
import Helpers from './Helpers.js'


const sollUsername = "7f02e0c36c6740de60131d72aca8c6c1d40e7b12963145aabdde88ce5d92f69d";
const sollPassword = "36dc3fb03f886b0ce256380825771a4b79bb2664220e757ba7a61c4fdc3814bf";

const Login = () => {
    const isAuthenticated = useIsAuthenticated()
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const loginHandler = async () => {
      //if (username === 'admin' && password === 'admin'){
      if (await Helpers.makeSha256Hash(await Helpers.makeSha256Hash(username)) === sollUsername 
      && await Helpers.makeSha256Hash(await Helpers.makeSha256Hash(password)) === sollPassword){
        if (signIn({
            token: '35v3443bn368367n306306wbn407qn420b436b4',
            tokenType: 'Bearer',
            authState: {name: 'React User', uid: 123456},
            expiresIn: 1440,
            SameSite: "none"
          })) {
            navigate('/paalstats')
          } else {
            alert("Error Occoured. Try Again")
          }
      } else {
        alert('Invalid credentials');
    }
     
    }
    // console.log(isAuthenticated())
    if (isAuthenticated()) {  
      return (
        <Navigate to={'/paalstats'} replace/>
      )
    } else {

      return (
        <div className="login-container">
            <div className="login-form">
                <h2>Login</h2>
                <input 
                    className="login-input"
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') loginHandler();
                    }}
                    placeholder="Username"
                />
                <input 
                  className="login-input"
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') loginHandler();
                    }}
                    placeholder="Password"
                />
                <button onClick={loginHandler}>Login</button>
            </div>

            {/* <div>
                <input 
                    type="text" 
                    value={text} 
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter text" 
                />
                <button onClick={handleHashText}>Hash Text</button>
            </div> */}
        </div>
        


        
      )
    }
  }

export default Login;
