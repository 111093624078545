import React from 'react'
import { RequireAuth } from 'react-auth-kit'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Login'
import Loading from './Loading';  // Import the Loading component

const PaalStats = React.lazy(() => import('./PaalStats'));

const RoutesComponent = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Login/>}/>
                <Route path={'/login' } element={<Login/>}/>
                <Route path={'/paalstats'} element={
                  <RequireAuth loginPath={'/login'}>
                    <React.Suspense fallback={<Loading />}>
                        <PaalStats/>
                    </React.Suspense>
                  </RequireAuth>
                }/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesComponent