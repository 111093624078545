import { useState, useEffect } from 'react';
import axios from 'axios';
/* global BigInt */


const formatNumber = (number, digits) => {
    return new Intl.NumberFormat('en-US', { 
        style: 'decimal', 
        minimumFractionDigits: digits, 
        maximumFractionDigits: digits 
    }).format(number);
};

// get latest price from coingecko based on coinId (ethereum, paal-ai)
const CoinPrice = ({ coinId, digits }) => {
    const [price, setPrice] = useState(null);

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const url = `https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`;

                const response = await axios.get(url);
                setPrice(response.data[coinId].usd); // Access the price based on coinId
            } catch (error) {
                console.error("Error fetching the price", error);
            }
        };

        fetchPrice();
    }, [coinId]);

    return price ? price : 'Loading...';
};

function getBigUint64( view, position, littleEndian = false )
{
  if ( "getBigUint64" in DataView.prototype )
  {
    return view.getBigUint64( position, littleEndian );
  }
  else
  {
    const lsb = BigInt( view.getUint32( position + (littleEndian ? 0 : 4), littleEndian ) );
    const gsb = BigInt( view.getUint32( position + (littleEndian ? 4 : 0), littleEndian ) );
    return lsb + 4294967296n * gsb;
  }
}

function getBigInt64( view, position, littleEndian = false )
{
  if ( "getBigInt64" in DataView.prototype )
  {
    return view.getBigInt64( position, littleEndian );
  }
  else
  {
    let value = 0n;
    let isNegative = ( view.getUint8( position + ( littleEndian ? 7 : 0 ) ) & 0x80 ) > 0;
    let carrying = true;
    for ( let i = 0; i < 8; i++ )
    {
      let byte = view.getUint8( position + ( littleEndian ? i : 7 - i ) );
      if ( isNegative )
      {
        if ( carrying )
        {
          if ( byte !== 0x00 )
          {
            byte = (~(byte - 1))&0xFF;
            carrying = false;
          }
        }
        else
        {
          byte = (~byte)&0xFF;
        }
      }
      value += BigInt(byte) * 256n**BigInt(i);
    }
    if ( isNegative )
    {
      value = -value;
    }
    return value;
  }
}


function hexToArrayBuffer(hexString) {
  if (hexString.length % 2 !== 0) {
      throw new Error("Invalid hex string");
  }
  var buffer = new ArrayBuffer(hexString.length / 2);
  var view = new DataView(buffer);
  for (var i = 0; i < hexString.length; i += 2) {
      view.setUint8(i / 2, parseInt(hexString.substring(i, i + 2), 16));
  }
  return buffer;
}


function formatDays(days) {
  // Extract the whole number of days
  const wholeDays = Math.floor(days);

  // Extract the fractional part of days and convert it to hours
  const hours = (days - wholeDays) * 24;
  const wholeHours = Math.floor(hours);

  // Extract the fractional part of hours and convert it to minutes
  const minutes = Math.floor((hours - wholeHours) * 60);

  return `${wholeDays}d ${wholeHours}h ${minutes}m`;
}


function getClosestFullDay(unixTimestamp) {
  // Create a new date object from the given Unix timestamp
  const date = new Date(unixTimestamp * 1000);

  // Create date objects for the start and end of the day in UTC
  const startOfDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
  const endOfDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

  // Calculate the differences between the given timestamp and the start/end of the day
  const diffStart = Math.abs(date - startOfDay);
  const diffEnd = Math.abs(date - endOfDay);

  // Choose the closest full day (start or end)
  const closestDay = diffStart < diffEnd ? startOfDay : new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1));

  // Extract the day, month, and year components and format the date
  const day = String(closestDay.getUTCDate()).padStart(2, '0');
  const month = String(closestDay.getUTCMonth() + 1).padStart(2, '0');  // Months are 0-indexed in JS
  const year = closestDay.getUTCFullYear();

  return `${day}-${month}-${year}`;
}


const makeSha256Hash = async (text) => {
  try {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  //console.log(hashHex);
  return hashHex;
  } catch (error) {
  console.error('Error hashing text:', error);
  }
};

const Helpers = {
    formatNumber,
    CoinPrice,
    getBigUint64,
    getBigInt64,
    hexToArrayBuffer,
    formatDays,
    getClosestFullDay,
    makeSha256Hash,

};

export default Helpers;